<template>
  <div class="col-12">
    <div>
      <span
        ><strong>{{ message.member.name }}</strong></span
      >
      <span v-if="message.serverTimeReceived" class="receivedTime">{{ getNiceTime(message.serverTimeReceived) }}</span>
    </div>
    <div class="message-row shadow">{{ message.content }}</div>
  </div>
</template>

<script>
import { eventBus } from "@/eventbus";
import { getNiceTime } from "@/utils/time";

export default {
  components: {},
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getNiceTime,
  },
};
</script>

<style lang="scss" scoped>
.borderless td {
  border: none;
}

.message-row {
  background-color: white;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  align-items: center;
  transition: $elevation1-transition;
  white-space: pre-wrap;
  border-radius: 0.5rem;
  display: inline-block;
}

.receivedTime {
  margin-left: 0.5rem;
}
</style>
