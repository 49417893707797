<template>
  <div>
    <c-messages-list :channel-id="channelId" />
  </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import CMessagesList from "@/components/channel/CMessagesList";

export default {
  components: { CMessagesList },
  mixins: [AuthenticatedMixin],
  props: {
    channelId: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
