<template>
  <div class="row">
    <div class="messages">
      <p-message v-for="message in messages" :key="message.id" :message="message" class="message-entry" />
      <p-message v-if="lastSentMessage" :message="lastSentMessage" class="message-entry" />
    </div>
    <div class="col-12 ">
      <textarea
        v-model="currentMessageInput"
        class="enter-message-textarea form-control"
        placeholder="Nachricht eingeben..."
      />
      <button :disabled="lastSentMessage" class="col-12 send-button btn btn-primary" @click="send(currentMessageInput)"
        >Senden
      </button>
    </div>
  </div>
</template>

<script>
import { services } from "@/main";
import { eventBus } from "@/eventbus";
import PMessage from "@/components/channel/PMessage";
import uuid from "uuid";
import { ChannelMessages, MemberInformation } from "@/services/message/MessageServiceResponses";

export default {
  components: {
    PMessage,
  },
  props: {
    channelId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentMessageInput: "",
      lastSentMessage: null,
      memberRequest: new MemberInformation(),
      messagesRequest: new ChannelMessages(),
      sendMessageRequest: { loaded: true, error: null },
    };
  },
  computed: {
    messages() {
      if (!this.messagesRequest.loaded || this.messagesRequest.error) {
        return [];
      }

      return this.messagesRequest.apiData;
    },
    member() {
      if (!this.memberRequest.loaded || this.memberRequest.error) {
        return {};
      }

      return this.memberRequest.apiData;
    },
  },
  watch: {
    "sendMessageRequest.loaded": function() {
      if (!this.sendMessageRequest.loaded || this.sendMessageRequest.error) {
        return;
      }

      if (this.lastSentMessage.id === this.sendMessageRequest.apiData.messageId) {
        this.lastSentMessage.serverTimeReceived = this.sendMessageRequest.apiData.serverTimeReceived;
        this.messages.push(this.lastSentMessage);
        this.lastSentMessage = null;
      }
    },
  },
  created() {
    this.memberRequest = services.messageService.getMemberInformation();
    this.messagesRequest = services.messageService.getChannelMessages(this.channelId);
  },
  methods: {
    send: function(content) {
      const messageId = uuid();

      this.lastSentMessage = {
        id: messageId,
        content: content,
        serverTimeReceived: null,
        member: {
          you: true,
          name: this.member.name,
          id: this.member.id,
        },
      };
      this.sendMessageRequest = services.messageService.sendMessage(this.channelId, content, messageId);
      this.currentMessageInput = "";
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  padding: 15px;
  resize: none;
  min-height: 180px;
}

textarea:focus {
  outline: none;
}

.send-button {
  text-align: center;
  margin-top: 1rem;
}

.messages {
  margin-bottom: 1.5rem;
}

.enter-message-textarea {
  resize: vertical;
}
</style>
